import React from "react";
import "./about.scss";
import logo from "../../Media/KHI_Green.png";

const about = () => {
  return (
    <div id="about" className="containerabout">
      <div className="containerlogo">
        <img src={logo} alt="logo_HI" />
        <div className="subtitle">Embrace the Beauty of Hopeful Living </div>
      </div>
      <div className="containerdesk">
        <div className="desk">
          Kota Harapan Indah adalah sebuah kawasan mandiri yang terletak di
          perbatasan antara Bekasi dan Jakarta Timur. Kota Harapan Indah
          memiliki luas sekitar 2.200 hektar dan dikembangkan oleh PT Damai
          Putra Group sejak tahun 1993. Kota Harapan Indah dilengkapi dengan
          berbagai fasilitas publik yang mendukung kehidupan sehari-hari
          penghuninya.
        </div>
      </div>
    </div>
  );
};

export default about;
