import React from "react";
import "./Produk3.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Jasmiaimages from "./Jasmia";
import Laveshimages from "./Lavesh";
import Nishiimages from "./Nishi";
import Albasiaimages from "./Albasia";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Nishiwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Asera%20Nishi)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Laveshwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Lavesh)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Jasmiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Jasmia)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Albasiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Albasia)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="container-card4">
        <div className="card">
          <Slider {...settings}>
            {Albasiaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Albasia</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>

          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 81m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 64m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Albasiawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Nishiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Asera Nishi</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 60m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 55m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span>2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Nishiwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Laveshimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Lavesh</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>

          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 75m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 57m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Laveshwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
