import Kana1 from '../../../Media/KHI/Kana/Kana (1).webp'
import Kana2 from '../../../Media/KHI/Kana/Kana (2).webp'
import Kana3 from '../../../Media/KHI/Kana/Kana (3).webp'
import Kana4 from '../../../Media/KHI/Kana/Kana (4).webp'
import Kana5 from '../../../Media/KHI/Kana/Kana (5).webp'
import Kana6 from '../../../Media/KHI/Kana/Kana (6).webp'
import Kana7 from '../../../Media/KHI/Kana/Kana (7).webp'
import Kana8 from '../../../Media/KHI/Kana/Kana (8).webp'
import Kana9 from '../../../Media/KHI/Kana/Kana (9).webp'



const Kanaimages =[
Kana1,Kana2,Kana3,Kana4,Kana5,Kana6,Kana7,Kana8,Kana9
]

export default Kanaimages;