import Nismara1 from '../../../Media/KHI/Nismara/Nismara (1).webp'
import Nismara2 from '../../../Media/KHI/Nismara/Nismara (2).webp'
import Nismara3 from '../../../Media/KHI/Nismara/Nismara (3).webp'
import Nismara4 from '../../../Media/KHI/Nismara/Nismara (4).webp'
import Nismara5 from '../../../Media/KHI/Nismara/Nismara (5).webp'
import Nismara6 from '../../../Media/KHI/Nismara/Nismara (6).webp'
import Nismara7 from '../../../Media/KHI/Nismara/Nismara (7).webp'
import Nismara8 from '../../../Media/KHI/Nismara/Nismara (8).webp'
import Nismara9 from '../../../Media/KHI/Nismara/Nismara (9).webp'
import Nismara10 from '../../../Media/KHI/Nismara/Nismara (10).webp'


const Nismaraimages =[
Nismara1,Nismara2,Nismara3,Nismara4,Nismara5,Nismara6,Nismara7,Nismara8,Nismara9,Nismara10
]

export default Nismaraimages;