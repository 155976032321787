import Albasia1 from '../../../Media/KHI/Albasia/Albasia (1).webp'
import Albasia2 from '../../../Media/KHI/Albasia/Albasia (2).webp'
import Albasia3 from '../../../Media/KHI/Albasia/Albasia (3).webp'
import Albasia4 from '../../../Media/KHI/Albasia/Albasia (4).webp'
import Albasia5 from '../../../Media/KHI/Albasia/Albasia (5).webp'
import Albasia6 from '../../../Media/KHI/Albasia/Albasia (6).webp'
import Albasia7 from '../../../Media/KHI/Albasia/Albasia (7).webp'
import Albasia8 from '../../../Media/KHI/Albasia/Albasia (8).webp'



const Albasiaimages =[
Albasia1,Albasia2,Albasia3,Albasia4,Albasia5,Albasia6,Albasia7,Albasia8
]

export default Albasiaimages;