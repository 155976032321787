import Gallery1 from '../../../src/Media/landscape/Nishi.webp'
import Gallery2 from '../../../src/Media/landscape/Nismara.webp'
import Gallery3 from '../../../src/Media/landscape/Albasia.webp'
import Gallery4 from '../../../src/Media/landscape/Elora.webp'






const Galleryimages =[
    Gallery1,Gallery2,Gallery3,Gallery4
]

export default Galleryimages;