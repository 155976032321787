import Teradamai1 from '../../../Media/KHI/Teradamai/Teradamai (1).webp'
import Teradamai2 from '../../../Media/KHI/Teradamai/Teradamai (2).webp'
import Teradamai3 from '../../../Media/KHI/Teradamai/Teradamai (3).webp'
import Teradamai4 from '../../../Media/KHI/Teradamai/Teradamai (4).webp'
import Teradamai5 from '../../../Media/KHI/Teradamai/Teradamai (5).webp'
import Teradamai6 from '../../../Media/KHI/Teradamai/Teradamai (6).webp'
import Teradamai7 from '../../../Media/KHI/Teradamai/Teradamai (7).webp'
import Teradamai8 from '../../../Media/KHI/Teradamai/Teradamai (8).webp'
import Teradamai9 from '../../../Media/KHI/Teradamai/Teradamai (9).webp'



const Teradamaiimages =[ 
    Teradamai1,Teradamai2,Teradamai3,Teradamai4,Teradamai5,Teradamai6,Teradamai7,Teradamai8,Teradamai9
]

export default Teradamaiimages;