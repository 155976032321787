import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Anagataimages from "./Anagata";
import Curtinaimages from "./Curtina";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLayerGroup,
  faLocationDot,
  faMedal,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Curtinawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Harapan%20Indah%20(Ruko%20Curtina)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Anagatawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Harapan%20Indah%20(Ruko%20Anagata)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Curtinaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Ruko Curtina</div>
            <div className="gridmap">
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 56m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 69m²</span>
            <FontAwesomeIcon color="#091c34" icon={faLayerGroup} />
            <span> 2</span>
            <FontAwesomeIcon color="#091c34" icon={faToilet} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Curtinawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Anagataimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Ruko Anagata</div>
            <div className="gridmap">
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 57m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 88m²</span>
            <FontAwesomeIcon color="#091c34" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faToilet} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Anagatawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
