import Nishi1 from '../../../Media/KHI/Nishi/Nishi (1).webp'
import Nishi2 from '../../../Media/KHI/Nishi/Nishi (2).webp'
import Nishi3 from '../../../Media/KHI/Nishi/Nishi (3).webp'
import Nishi4 from '../../../Media/KHI/Nishi/Nishi (4).webp'
import Nishi5 from '../../../Media/KHI/Nishi/Nishi (5).webp'
import Nishi6 from '../../../Media/KHI/Nishi/Nishi (6).webp'
import Nishi7 from '../../../Media/KHI/Nishi/Nishi (7).webp'
import Nishi8 from '../../../Media/KHI/Nishi/Nishi (8).webp'
import Nishi9 from '../../../Media/KHI/Nishi/Nishi (9).webp'


const Nishiimages =[
Nishi1,Nishi2,Nishi3,Nishi4,Nishi5,Nishi6,Nishi7,Nishi8,Nishi9
]

export default Nishiimages;