import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import About from "../Section/About/about.jsx";
import Banner from "../Section/Banner/banner.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Gallery from "../Section/secondhero/SecondHero.jsx";
import Rumah from "../Section/Rumah/rumah.jsx";
import Ruko from "../Section/Ruko/ruko.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Surrounding from "../Section/surrounding/Surrounding.jsx";
import Footer from "../Section/Footer/footer.jsx";
import tombol from "../../src/Media/tombol.webp";
import ScrollToHashElement from "../Pages/ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Gallery />
      <Promo />
      <Rumah />
      <Ruko />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
