import Lavesh1 from '../../../Media/KHI/Lavesh/Lavesh (1).webp'
import Lavesh2 from '../../../Media/KHI/Lavesh/Lavesh (2).webp'
import Lavesh3 from '../../../Media/KHI/Lavesh/Lavesh (3).webp'
import Lavesh4 from '../../../Media/KHI/Lavesh/Lavesh (4).webp'
import Lavesh5 from '../../../Media/KHI/Lavesh/Lavesh (5).webp'
import Lavesh6 from '../../../Media/KHI/Lavesh/Lavesh (6).webp'
import Lavesh7 from '../../../Media/KHI/Lavesh/Lavesh (7).webp'
import Lavesh8 from '../../../Media/KHI/Lavesh/Lavesh (8).webp'
import Lavesh9 from '../../../Media/KHI/Lavesh/Lavesh (9).webp'

const Laveshimages =[
Lavesh1,Lavesh2,Lavesh3,Lavesh4,Lavesh5,Lavesh6,Lavesh7,Lavesh8,Lavesh9
]

export default Laveshimages;