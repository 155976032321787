import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Eloraimages from "./Elora";
import Teradamaiimages from "./Teradamai";
import Violaimages from "./Viola";
import Kanaimages from "./Kana";
import Nismaraimages from "./Nismara";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Teradamaiwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Teradamai)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Elorawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Elora)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Violawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Viola)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Kanawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Kana)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Nismarawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281388880983&text=Halo%20Maida,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Nismara)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Teradamaiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Teradamai</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 60m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 33m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">2 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Teradamaiwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Eloraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Elora</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 70m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 65m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">3 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Elorawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>

        <div className="card">
          <Slider {...settings}>
            {Kanaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Kana</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 40m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 48m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">5 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Kanawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Nismaraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Nismara</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 66m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 65m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Nismarawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
