import React from "react";
import "./Secondhero.scss";
import kiriatas from "../../Media/gallery/Nismara.png";
import kiribawah from "../../Media/gallery/Nishi.png";
import kananatas from "../../Media/gallery/Albasia.png";
import kananbawah from "../../Media/gallery/Lavesh.png";
import Galleryimages from "./galerry";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SecondHero = () => {
  return (
    <div className="secondherocontainer" id="About">
      <div className="secondmiddle">
        <div className="leftcontent">
          <div className="judul">
            Dapatkan Penawaran Rumah Siap Huni Kota Harapan Indah
          </div>
          <div className="clusterlist">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>
                &nbsp;&nbsp;Cluster Asia Tropis
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>&nbsp;&nbsp;Cluster Samata</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>&nbsp;&nbsp;Cluster Adara</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>
                &nbsp;&nbsp;Cluster Asera (East, West, South)
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>&nbsp;&nbsp;Cluster Arana</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>&nbsp;&nbsp;Cluster Greenara</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "grey"}}>&nbsp;&nbsp;Cluster Kana</span>
            </div>
          </div>
        </div>
        <div className="rightcontent">
          <div className="leftpicture">
            <div className="kiriatas">
              <img src={kiriatas} className="satu" alt="Nava" />
            </div>
            <div className="kiribawah">
              <img src={kiribawah} className="dua" alt="Nava" />
            </div>
          </div>
          <div className="rightpicture">
            <div className="kananatas">
              <img src={kananatas} className="tiga" alt="Nava" />
            </div>
            <div className="kananbawah">
              <img src={kananbawah} className="empat" alt="Nava" />
            </div>
          </div>
        </div>
        <div className="container-card">
          <div className="kartu">
            <Slider {...settings}>
              {Galleryimages.map((image, index) => (
                <div>
                  <img
                    className="kartugambar1"
                    key={index}
                    src={image}
                    alt="athena"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondHero;
