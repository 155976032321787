import Elora1 from '../../../Media/KHI/Elora/Elora (1).webp'
import Elora2 from '../../../Media/KHI/Elora/Elora (2).webp'
import Elora3 from '../../../Media/KHI/Elora/Elora (3).webp'
import Elora4 from '../../../Media/KHI/Elora/Elora (4).webp'
import Elora5 from '../../../Media/KHI/Elora/Elora (5).webp'
import Elora6 from '../../../Media/KHI/Elora/Elora (6).webp'
import Elora7 from '../../../Media/KHI/Elora/Elora (7).webp'
import Elora8 from '../../../Media/KHI/Elora/Elora (8).webp'
import Elora9 from '../../../Media/KHI/Elora/Elora (9).webp'
import Elora10 from '../../../Media/KHI/Elora/Elora (10).webp'


const Eloraimages =[
Elora1,Elora2,Elora3,Elora4,Elora5,Elora6,Elora7,Elora8,Elora9,Elora10
]

export default Eloraimages;