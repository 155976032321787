import React from "react";
import "./ruko.scss";
import Ruko1 from "./Produk1/ruko1";

import Slider from "react-slick";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ruko = () => {
  return (
    <div id="ruko" className="ruko">
      <div className="judul-komersil">
        RUKO COMMERCIAL IN KOTA HARAPAN INDAH
      </div>

      <div>
        <div className="Judul"></div>
        <div className="ruko-card">
          <Ruko1 />
        </div>
      </div>
    </div>
  );
};

export default ruko;
